'use client';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useAlertStore from '@/hooks/use-alert-store';

export function QuestionAlertModal() {
	const { isOpen, closeModal, title, message, onConfirm } = useAlertStore();

	return (
		<AlertDialog open={isOpen}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{message}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={closeModal}>
						Hayır
					</AlertDialogCancel>
					<AlertDialogAction
						onClick={() => {
							if (onConfirm) onConfirm();
							closeModal();
						}}
					>
						Evet
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
