'use client';
import type React from 'react';
import { ThemeProvider } from './theme-provider';
import BuildProvidersTree from '@/lib/buildProvidersTree';
import QueryProvider from './tanstack-provider';

type Props = {
	children: React.ReactNode;
};

function Providers({ children }: Props) {
	const ProvidersTree = BuildProvidersTree([
		[QueryProvider],
		[
			ThemeProvider,
			{
				attribute: 'class',
				defaultTheme: 'system',
				enableSystem: true,
				disableTransitionOnChange: false,
			},
		],
	]);

	return <ProvidersTree>{children}</ProvidersTree>;
}

export default Providers;
