'use client';
import {
	AlertDialog,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useAlertStore from '@/hooks/use-alert-store';
import { buttonVariants } from '../ui/button';
import { OctagonX } from 'lucide-react';

export function ErrorAlertModal() {
	const { isOpen, closeModal, title, message } = useAlertStore();

	return (
		<AlertDialog open={isOpen}>
			<AlertDialogContent
				className={
					'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive'
				}
			>
				<AlertDialogHeader>
					<OctagonX />
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{message}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel
						className={buttonVariants({ variant: 'destructive' })}
						onClick={closeModal}
					>
						Tamam
					</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
