'use client';
import {
	AlertDialog,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useAlertStore from '@/hooks/use-alert-store';
import { CircleCheck } from 'lucide-react';

export function SuccessAlertModal() {
	const { isOpen, closeModal, title, message } = useAlertStore();

	return (
		<AlertDialog open={isOpen}>
			<AlertDialogContent
				className={
					'text-success border-success/50 dark:border-success [&>svg]:text-success'
				}
			>
				<AlertDialogHeader>
					<CircleCheck />
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{message}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={closeModal}>
						Tamam
					</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
