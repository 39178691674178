'use client';
import React from 'react';

import useAlertStore from '@/hooks/use-alert-store';
import { QuestionAlertModal } from './QuestionAlertModal';
import { ErrorAlertModal } from './ErrorAlertModal';
import { WarningAlertModal } from './WarningAlertModal';
import { SuccessAlertModal } from './SuccessAlertModal';
import { InfoAlertModal } from './InfoAlertModal';

const GlobalAlertManager = () => {
	const { type } = useAlertStore();

	return (
		<>
			{
				{
					info: <InfoAlertModal />,
					question: <QuestionAlertModal />,
					error: <ErrorAlertModal />,
					success: <SuccessAlertModal />,
					warning: <WarningAlertModal />,
				}?.[type]
			}
		</>
	);
};

export default GlobalAlertManager;
