'use client';
import {
	AlertDialog,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useAlertStore from '@/hooks/use-alert-store';
import { buttonVariants } from '../ui/button';
import { OctagonX, TriangleAlert } from 'lucide-react';

export function WarningAlertModal() {
	const { isOpen, closeModal, title, message } = useAlertStore();

	return (
		<AlertDialog open={isOpen}>
			<AlertDialogContent
				className={
					'text-warning border-warning/50 dark:border-warning [&>svg]:text-warning'
				}
			>
				<AlertDialogHeader>
					<TriangleAlert />
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{message}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={closeModal}>
						Tamam
					</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
