import { create } from 'zustand';

type AlertType = 'info' | 'question' | 'success' | 'warning' | 'error';

type AlertStore = {
	isOpen: boolean;
	message: string | React.ReactNode | JSX.Element;
	title: string;
	type: AlertType;
	onConfirm: null | (() => void);
	openModal: (
		message: string | React.ReactNode | JSX.Element,
		title: string,
		type?: AlertType,
		onConfirm?: () => void,
	) => void;
	closeModal: () => void;
};

const useAlertStore = create<AlertStore>((set) => ({
	isOpen: false,
	message: '',
	type: 'info', // Default modal type
	title: '', // Default modal title
	onConfirm: null, // Callback function for confirm action
	openModal: (
		message: string | React.ReactNode | JSX.Element,
		title: string,
		type: AlertType = 'info',
		onConfirm: (() => void) | null = null,
	) => set({ isOpen: true, message, title, type, onConfirm }),
	closeModal: () =>
		set({
			isOpen: false,
			message: '',
			title: '',
			type: 'info',
			onConfirm: null,
		}),
}));

export default useAlertStore;
