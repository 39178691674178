import React from 'react';

type ProvidersType = [React.ElementType, Record<string, unknown>?];
type ChildrenType = React.ElementType;

const BuildProvidersTree = (componentsWithProps: ProvidersType[]) => {
	const initialComponent = ({
		children,
	}: {
		children: React.ElementType[];
	}) => <>{children}</>;
	return componentsWithProps.reduce(
		(
			AccumulatedComponents: ChildrenType,
			[Providers, props = {}]: ProvidersType,
		) => {
			//eslint-disable-next-line react/display-name
			return ({ children }: { children: ChildrenType[] }) => {
				return (
					<AccumulatedComponents>
						<Providers {...props}>{children}</Providers>
					</AccumulatedComponents>
				);
			};
		},
		initialComponent,
	);
};

BuildProvidersTree.displayName = 'BuildProvidersTree';

export default BuildProvidersTree;
