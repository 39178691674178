import {
	HydrationBoundary,
	isServer,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import type React from 'react';

type Props = {
	children: React.ReactNode;
};

function makeQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				// With SSR, we usually want to set some default staleTime
				// above 0 to avoid refetching immediately on the client
				refetchOnWindowFocus: true,
				refetchOnMount: true,
				refetchInterval: false,
				refetchIntervalInBackground: true,
				retry: 0,
				staleTime: 60 * 500,
			},
		},
	});
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
	if (isServer) {
		// Server: always make a new query client
		return makeQueryClient();
	}

	if (!browserQueryClient) browserQueryClient = makeQueryClient();
	return browserQueryClient;
}

function QueryProvider({ children }: Props) {
	const queryClient = getQueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary>
				{children}
				<ReactQueryDevtools initialIsOpen={false} />
			</HydrationBoundary>
		</QueryClientProvider>
	);
}

export default QueryProvider;
